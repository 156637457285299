exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dercampus-easee-innovationen-in-der-epilepsietherapie-fortbildung-2412-tsx": () => import("./../../../src/pages/dercampus-easee-innovationen-in-der-epilepsietherapie-fortbildung-2412.tsx" /* webpackChunkName: "component---src-pages-dercampus-easee-innovationen-in-der-epilepsietherapie-fortbildung-2412-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-newsletter-signup-success-tsx": () => import("./../../../src/pages/newsletter-signup-success.tsx" /* webpackChunkName: "component---src-pages-newsletter-signup-success-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-clinical-studies-demonstrate-that-patients-with-focal-epilepsies-benefit-from-the-technologically-innovative-therapy-easee-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/clinical-studies-demonstrate-that-patients-with-focal-epilepsies-benefit-from-the-technologically-innovative-therapy-easee/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-clinical-studies-demonstrate-that-patients-with-focal-epilepsies-benefit-from-the-technologically-innovative-therapy-easee-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-clinical-studies-demonstrate-that-patients-with-focal-epilepsies-benefit-from-the-technologically-innovative-therapy-easee-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/clinical-studies-demonstrate-that-patients-with-focal-epilepsies-benefit-from-the-technologically-innovative-therapy-easee/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-clinical-studies-demonstrate-that-patients-with-focal-epilepsies-benefit-from-the-technologically-innovative-therapy-easee-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-receives-ce-certification-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/easee-receives-ce-certification/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-receives-ce-certification-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-receives-ce-certification-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/easee-receives-ce-certification/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-receives-ce-certification-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-system-successfully-implanted-at-coimbra-university-hospital-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/easee-system-successfully-implanted-at-coimbra-university-hospital/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-system-successfully-implanted-at-coimbra-university-hospital-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-system-successfully-implanted-at-coimbra-university-hospital-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/easee-system-successfully-implanted-at-coimbra-university-hospital/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-system-successfully-implanted-at-coimbra-university-hospital-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-the-innovation-in-epilepsy-treatment-the-groundbreaking-implant-in-the-spotlight-of-medtech-outlook-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/easee-the-innovation-in-epilepsy-treatment-the-groundbreaking-implant-in-the-spotlight-of-medtech-outlook/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-the-innovation-in-epilepsy-treatment-the-groundbreaking-implant-in-the-spotlight-of-medtech-outlook-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-the-innovation-in-epilepsy-treatment-the-groundbreaking-implant-in-the-spotlight-of-medtech-outlook-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/easee-the-innovation-in-epilepsy-treatment-the-groundbreaking-implant-in-the-spotlight-of-medtech-outlook/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-easee-the-innovation-in-epilepsy-treatment-the-groundbreaking-implant-in-the-spotlight-of-medtech-outlook-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-first-prize-of-the-esteemed-american-epilepsy-foundation-shark-tank-goes-to-precisis-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/first-prize-of-the-esteemed-american-epilepsy-foundation-shark-tank-goes-to-precisis/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-first-prize-of-the-esteemed-american-epilepsy-foundation-shark-tank-goes-to-precisis-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-first-prize-of-the-esteemed-american-epilepsy-foundation-shark-tank-goes-to-precisis-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/first-prize-of-the-esteemed-american-epilepsy-foundation-shark-tank-goes-to-precisis/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-first-prize-of-the-esteemed-american-epilepsy-foundation-shark-tank-goes-to-precisis-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-heidelberg-innovation-park-ob-wurzner-besuchte-innovatives-medizintechnik-unternehmen-precisis-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/heidelberg-innovation-park-ob-wurzner-besuchte-innovatives-medizintechnik-unternehmen-precisis/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-heidelberg-innovation-park-ob-wurzner-besuchte-innovatives-medizintechnik-unternehmen-precisis-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-med-tech-company-precisis-expands-with-appointment-of-top-executive-as-new-ceo-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/med-tech-company-precisis-expands-with-appointment-of-top-executive-as-new-ceo/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-med-tech-company-precisis-expands-with-appointment-of-top-executive-as-new-ceo-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-med-tech-company-precisis-expands-with-appointment-of-top-executive-as-new-ceo-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/med-tech-company-precisis-expands-with-appointment-of-top-executive-as-new-ceo/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-med-tech-company-precisis-expands-with-appointment-of-top-executive-as-new-ceo-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-minimally-invasive-epilepsy-treatment-easee-receives-fda-breakthrough-device-designation-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/minimally-invasive-epilepsy-treatment-easee-receives-fda-breakthrough-device-designation/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-minimally-invasive-epilepsy-treatment-easee-receives-fda-breakthrough-device-designation-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-minimally-invasive-epilepsy-treatment-easee-receives-fda-breakthrough-device-designation-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/minimally-invasive-epilepsy-treatment-easee-receives-fda-breakthrough-device-designation/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-minimally-invasive-epilepsy-treatment-easee-receives-fda-breakthrough-device-designation-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-new-data-just-published-online-in-the-prestigious-journal-jama-neurology-has-demonstrated-the-safety-and-efficacy-of-easee-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/new-data-just-published-online-in-the-prestigious-journal-jama-neurology-has-demonstrated-the-safety-and-efficacy-of-easee/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-new-data-just-published-online-in-the-prestigious-journal-jama-neurology-has-demonstrated-the-safety-and-efficacy-of-easee-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-new-data-just-published-online-in-the-prestigious-journal-jama-neurology-has-demonstrated-the-safety-and-efficacy-of-easee-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/new-data-just-published-online-in-the-prestigious-journal-jama-neurology-has-demonstrated-the-safety-and-efficacy-of-easee/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-new-data-just-published-online-in-the-prestigious-journal-jama-neurology-has-demonstrated-the-safety-and-efficacy-of-easee-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-pearl-study-initiated-to-assess-the-long-term-effects-of-the-easee-brain-stimulator-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/pearl-study-initiated-to-assess-the-long-term-effects-of-the-easee-brain-stimulator/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-pearl-study-initiated-to-assess-the-long-term-effects-of-the-easee-brain-stimulator-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-pearl-study-initiated-to-assess-the-long-term-effects-of-the-easee-brain-stimulator-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/pearl-study-initiated-to-assess-the-long-term-effects-of-the-easee-brain-stimulator/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-pearl-study-initiated-to-assess-the-long-term-effects-of-the-easee-brain-stimulator-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-celebrates-second-place-at-the-health-i-award-2024-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-celebrates-second-place-at-the-health-i-award-2024/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-celebrates-second-place-at-the-health-i-award-2024-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-celebrates-second-place-at-the-health-i-award-2024-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-celebrates-second-place-at-the-health-i-award-2024/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-celebrates-second-place-at-the-health-i-award-2024-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-concludes-funding-round-of-e-20-million-for-its-bioelectric-brain-stimulation-system-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-concludes-funding-round-of-e20-million-for-its-bioelectric-brain-stimulation-system/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-concludes-funding-round-of-e-20-million-for-its-bioelectric-brain-stimulation-system-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-concludes-funding-round-of-e-20-million-for-its-bioelectric-brain-stimulation-system-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-concludes-funding-round-of-e20-million-for-its-bioelectric-brain-stimulation-system/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-concludes-funding-round-of-e-20-million-for-its-bioelectric-brain-stimulation-system-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-gmbh-celebrates-win-at-the-innovation-award-of-the-federal-state-baden-wurttemberg-2023-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-gmbh-celebrates-win-at-the-innovation-award-of-the-federal-state-baden-wurttemberg-2023/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-gmbh-celebrates-win-at-the-innovation-award-of-the-federal-state-baden-wurttemberg-2023-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-gmbh-celebrates-win-at-the-innovation-award-of-the-federal-state-baden-wurttemberg-2023-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-gmbh-celebrates-win-at-the-innovation-award-of-the-federal-state-baden-wurttemberg-2023/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-gmbh-celebrates-win-at-the-innovation-award-of-the-federal-state-baden-wurttemberg-2023-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-has-introduced-easee-to-portugal-in-collaboration-with-pagaimo-medical-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-has-introduced-easee-to-portugal-in-collaboration-with-pagaimo-medical/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-has-introduced-easee-to-portugal-in-collaboration-with-pagaimo-medical-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-has-introduced-easee-to-portugal-in-collaboration-with-pagaimo-medical-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-has-introduced-easee-to-portugal-in-collaboration-with-pagaimo-medical/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-has-introduced-easee-to-portugal-in-collaboration-with-pagaimo-medical-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-launches-easee-in-the-uk-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-launches-easee-in-the-uk/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-launches-easee-in-the-uk-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-launches-easee-in-the-uk-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-launches-easee-in-the-uk/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-launches-easee-in-the-uk-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-receives-top-100-seal-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-receives-top-100-seal/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-receives-top-100-seal-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-receives-top-100-seal-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-receives-top-100-seal/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-receives-top-100-seal-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-unveils-its-brain-pacemaker-easee-iii-with-extended-three-years-battery-life-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-unveils-its-brain-pacemaker-easee-iii-with-extended-three-years-battery-life/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-unveils-its-brain-pacemaker-easee-iii-with-extended-three-years-battery-life-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-unveils-its-brain-pacemaker-easee-iii-with-extended-three-years-battery-life-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/press/precisis-unveils-its-brain-pacemaker-easee-iii-with-extended-three-years-battery-life/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-press-precisis-unveils-its-brain-pacemaker-easee-iii-with-extended-three-years-battery-life-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-carbon-reduction-plan-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/root/carbon-reduction-plan/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-carbon-reduction-plan-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-carbon-reduction-plan-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/root/carbon-reduction-plan/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-carbon-reduction-plan-en-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-legal-notice-de-md": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/root/legal-notice/de.md" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-legal-notice-de-md" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-legal-notice-en-md": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/root/legal-notice/en.md" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-legal-notice-en-md" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-privacy-policy-de-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/root/privacy-policy/de.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-privacy-policy-de-mdx" */),
  "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-privacy-policy-en-mdx": () => import("./../../../src/templates/markdown-page.jsx?__contentFilePath=/builds/techmynder/clients/precisis/precisis-website/src/markdown-pages/root/privacy-policy/en.mdx" /* webpackChunkName: "component---src-templates-markdown-page-jsx-content-file-path-src-markdown-pages-root-privacy-policy-en-mdx" */)
}

