import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import SEO from "./seo"

const Redirect = () => {
  const intl = useIntl()

  return <SEO title={intl.formatMessage({ id: "page.index.title" })} />
}

export default Redirect
